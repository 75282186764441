<template>
  <div v-loading="loading">
    <el-row>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="title_fw">项目投入统计</span>
          <span class="title_right"
            >统计时间：
            <el-select
              v-model="sectionInfo.putIntoProjectYear"
              size="small"
              :style="{ width: 100 + 'px' }"
              @change="onPutIntoYearChange"
            >
              <el-option
                v-for="item in dictData.yearList"
                :key="item.id"
                :label="item.dictName"
                :value="item.dictVal"
              >
              </el-option> </el-select
          ></span>
          <span class="title_right">
            项目经理：
            <el-select
              v-model="sectionInfo.putIntoManager"
              filterable
              clearable
              :disabled="putIntoManagerDisabled"
              size="small"
              :style="{ width: 100 + 'px' }"
              @change="onPutIntoManagerChange"
            >
              <el-option
                v-for="item in projectManagerlist"
                :key="item.id"
                :label="item.dictName"
                :value="item.dictVal"
              >
              </el-option>
            </el-select>
          </span>
          <span class="title_right">
            项目名称：
            <el-select
              v-model="sectionInfo.putIntoProjectId"
              filterable
              clearable
              size="small"
              :style="{ width: 400 + 'px' }"
              @change="onPutIntoProjectChange"
            >
              <el-option
                v-for="item in putIntoProjectlist"
                :key="item.id"
                :label="item.dictName"
                :value="item.dictVal"
              >
              </el-option>
            </el-select>
          </span>
          <el-button class="title_right" type="primary" size="small" @click="onPutIntoHistogramImg"
            >数据来源说明</el-button
          >
        </div>
        <PutIntoHistogram :projectPutIntoInfo="projectPutIntoInfo" />
      </el-card>
    </el-row>
    <el-row style="margin-top: 8px">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="title_fw">项目工时统计</span>
          <span class="title_right"
            ><SearchUserDate
              :type="'ProjectHourHistogram'"
              :introductionType="'month'"
              :twoIntroductionType="'projectId'"
              :width="'100'"
              :clearable="true"
              :list="monthGenerate"
              :yearValue="sectionInfo.projectHourYear"
              :twoValue="sectionInfo.projectHourMonth"
              :threeValue="sectionInfo.projectHourId"
              v-model="sectionInfo.projectHourMonth"
              @change="onProjectHourHistogramChange"
          /></span>
          <span class="title_right"
            ><SearchUserDate
              :type="'ProjectHourHistogram'"
              :introductionType="'month'"
              :twoIntroductionType="'projectId'"
              :width="'100'"
              :yearIsShow="true"
              :title="titleYear"
              :list="dictData.yearList"
              :twoValue="sectionInfo.projectHourMonth"
              :threeValue="sectionInfo.projectHourId"
              v-model="sectionInfo.projectHourYear"
              @change="onProjectHourHistogramChange"
          /></span>
          <span class="title_right"
            ><SearchUserDate
              :title="'项目名称：'"
              :width="'400'"
              :type="'ProjectHourHistogram'"
              :introductionType="'month'"
              :twoIntroductionType="'projectId'"
              :threeIsShow="true"
              :clearable="true"
              :filterable="true"
              :list="projectList"
              :yearValue="sectionInfo.projectHourYear"
              :twoValue="sectionInfo.projectHourMonth"
              :threeValue="sectionInfo.projectHourId"
              v-model="sectionInfo.projectHourId"
              @change="onProjectHourHistogramChange"
          /></span>
          <el-button
            class="title_right"
            type="primary"
            size="small"
            @click="onProjectHourHistogramImg"
            >数据来源说明</el-button
          >
        </div>
        <ProjectHourHistogram :projectHourList="projectHourList" />
      </el-card>
    </el-row>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          v-show="imgUrl == '项目统计-项目投入统计'"
          src="@/assets/项目统计-项目投入统计.png"
          alt="数据来源说明"
          width="100%"
        />
        <img
          v-show="imgUrl == '项目统计-项目工时统计'"
          src="@/assets/项目统计-项目工时统计.png"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
    ProjectHourHistogram: () => import('./projectHourHistogram.vue'),
    PutIntoHistogram: () => import('./putIntoHistogram.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      projectList: [], // 项目数组
      dictData: {
        yearList: [],
      },
      projectHourList: [], // 项目工时统计
      projectPutIntoInfo: {}, // 项目投入统计
      sectionInfo: {
        // 条件筛选 // 年份
        projectHourYear: null,
        putIntoProjectYear: null,
        personageHuorYear: null,
        projectHourMonth: null, // 月份
        projectHourId: null, // 项目工时统计名称
        putIntoProjectId: null, // 项目投入项目名称
        putIntoManager: '', // 项目投入项目经理
      },
      projectManagerlist: [], //项目经理
      putIntoProjectlist: [], //项目投入项目名称
      titleYear: '统计时间：',
      putIntoManagerDisabled: false,
      isDialog: false,
      imgUrl: '',
    }
  },
  provide() {
    return {}
  },
  computed: {
    monthGenerate() {
      const arr = []
      for (let index = 0; index < 12; index++) {
        arr.push({ id: index + 1, dictName: `${index + 1}月`, dictVal: `${index + 1}` })
      }
      return arr
    },
  },
  created() {
    this.getType()
  },
  filters: {},
  watch: {
    'sectionInfo.putIntoProjectId': {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.putIntoManagerDisabled = true
        } else {
          this.putIntoManagerDisabled = false
        }
      },
    },
  },
  mounted() {},
  methods: {
    // 通用函数：构建查询参数并发起请求
    async fetchProjectInputSummary(queryParams) {
      const res = await this.$api.statistics.queryProjectInputSummary(queryParams)
      this.projectPutIntoInfo = res.data?.projectInputResponseVo || {}
    },
    // 项目投入项目名称
    async onPutIntoProjectChange(val) {
      const queryParams = this.buildQueryParams({
        date: this.sectionInfo.putIntoProjectYear,
        projectManager: this.sectionInfo.putIntoManager,
        projectId: val,
      })
      await this.fetchProjectInputSummary(queryParams)
    },

    // 项目投入项目经理
    async onPutIntoManagerChange(val) {
      // 获取项目经理所属项目
      if (val) {
        const item = await this.$api.project.getProjectInfoByStaffId(`?projectManager=${val}`)
        this.putIntoProjectlist = item.data?.projectNameRespList.map(v => ({
          id: v.id,
          dictName: v.projectName,
          dictVal: v.id,
        }))
      } else {
        this.putIntoProjectlist = [...this.projectList]
      }
      const queryParams = this.buildQueryParams({
        date: this.sectionInfo.putIntoProjectYear,
        projectManager: val,
        projectId: this.sectionInfo.putIntoProjectId,
      })
      await this.fetchProjectInputSummary(queryParams)
    },

    // 项目投入年份
    async onPutIntoYearChange(val) {
      const queryParams = this.buildQueryParams({
        date: val,
        projectManager: this.sectionInfo.putIntoManager,
        projectId: this.sectionInfo.putIntoProjectId,
      })
      await this.fetchProjectInputSummary(queryParams)
    },

    // 通用函数：构建查询参数字符串
    buildQueryParams(params) {
      let queryParams = ''
      for (const [key, value] of Object.entries(params)) {
        if (value) {
          queryParams += `${queryParams ? '&' : ''}${key}=${value}`
        }
      }
      return queryParams
    },
    onProjectHourHistogramImg() {
      this.imgUrl = '项目统计-项目工时统计'
      this.isDialog = true
    },
    onPutIntoHistogramImg() {
      this.imgUrl = '项目统计-项目投入统计'
      this.isDialog = true
    },
    onProjectHourHistogramChange(arr) {
      this.projectHourList = arr
    },
    getType() {
      this.loading = true
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.projectHourYear = dictVal
          this.sectionInfo.putIntoProjectYear = dictVal
          const queryProjectInputSummary = await this.$api.statistics.queryProjectInputSummary(
            `date=${dictVal}`
          )
          this.projectPutIntoInfo = queryProjectInputSummary.data?.projectInputResponseVo || {}

          const queryProjectWorkHourSummary =
            await this.$api.statistics.queryProjectWorkHourSummary(`date=${dictVal}`)
          this.projectHourList = queryProjectWorkHourSummary.data?.projectWorkHourResponseList || []

          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
      this.$api.project
        .getProjectInfoByStaffId()
        .then(res => {
          this.projectList = res.data?.projectNameRespList.map(v => ({
            id: v.id,
            dictName: v.projectName,
            dictVal: v.id,
          }))
          this.putIntoProjectlist = [...this.projectList]
          this.projectManagerlist = res.data?.projectManagerNameRespList.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.projectManagerlist.unshift({
            id: '1',
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
</style>
